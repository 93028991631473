<section class="news u-padding-top-huge u-padding-bottom-large">
    <div class="news__wrapper">
        <div class="row">
            <h2 class="heading-secondary u-center-text u-margin-bottom-small">
                Aktuellt
            </h2>
            <div class="paragraph__text" *ngIf="news">
                <div class="row" *ngIf="news.imagePath">
                    <div class="col-1-of-2">
                        <div class="news__list">
                            <p class="paragraph news__list__text" style="white-space: pre-line;">
                                {{ news.text }}
                            </p>
                        </div>
                    </div>
                    <div class="col-1-of-2">
                        <div class="news__list__img">
                            <img src="/{{ news.imagePath }}" />
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!news.imagePath">
                    <div class="news__list">
                        <p class="paragraph news__list__text" style="white-space: pre-line;">
                            {{ news.text }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>