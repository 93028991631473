<div class="treatment u-padding-top-small u-padding-bottom-large">
  <div class="row">
    <div class="treatment__content u-box-shadow u-margin-top-huge">
      <h2 class="heading-secondary u-center-text u-margin-bottom-small">
        Behandlingar
      </h2>
      <p class="paragraph">
        Vårt mål är långsiktigt hållbar behandling av högsta kvalitet.
        Vi strävar alltid efter att ett besök hos oss ska vara en trygg och trevlig upplevelse för
        alla inblandade. Vid tandvårdsrädsla anpassar vi gärna behandlingen.
      </p>
      <p class="paragraph">Vi erbjuder alla former av allmäntandvård, både för barn och vuxna.</p>
      <dl>
        <dd>- Undersökningar och profylaktisk tandvård</dd>
        <dd>- Kariesbehandling</dd>
        <dd>- Behandling av tandlossning</dd>
        <dd>- Uppbyggnad av slitna bett med kronor och broar</dd>
        <dd>- Implantatbehandlingar</dd>
        <dd>- Bettskenor</dd>
        <dd>- Tandblekning</dd>
        <dd>- Vuxenortodonti (tandreglering) med ”aligners” från <a
            href="https://www.straumann.com/clearcorrect/en/patients.html" target="_blank">Clearcorrect.se</a></dd>
        </dl>
      <br />
      <p class="paragraph">
        Vi tar emot barn from 3 års ålder och tandvård för barn och ungdomar mellan 3-23 år
        är avgiftsfri.
      </p>
      <p class="paragraph">
        Vid komplicerade behandlingar samarbetar vi med specialisttandläkare.
      </p>
      <p class="paragraph">
        Implantatbehandlingar utförs främst med
        <a href="https://www.straumann.com/se/en/dental-professionals.html" target="_blank">
          Straumann
        </a>
        implantatsystem och
        <a href="https://www.dentsplysirona.com/sv-se/shop/implants/implantatsystem/astra-tech-implant-system.html"
          target="_blank">
          Astra densply
        </a>
        . De är båda väldokumenterat system med få komplikationer.
      </p>
    </div>
  </div>
</div>