import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../../../services/data-access.service';

@Component({
  selector: 'home-section-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class HomeFeaturesComponent {

  constructor(private _dataAccess: DataAccessService) { }

 scrollTop() {    
    this._dataAccess.scrollTop();
  }
}
