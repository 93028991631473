import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { OpenHoursComponent } from "./pages/open-hours/open-hours.component";
import { TreatmentComponent } from "./pages/treatment/treatment.component";
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PricesComponent } from "./pages/prices/prices.component";
import { BookingComponent } from "./pages/booking/booking.component";
import { IntegrityPolicyComponent } from "./pages/integrity-policy/integrity-policy.component";

const routes: Routes = [
  { path: "", redirectTo: "hem", pathMatch: "full" },
  { path: "hem", component: HomeComponent },
  { path: "oppettider", component: OpenHoursComponent },
  { path: "behandlingar", component: TreatmentComponent },
  { path: "om-oss", component: AboutUsComponent },
  { path: "priser", component: PricesComponent },
  { path: "tidsbokning", component: BookingComponent },
  { path: "integritetspolicy", component: IntegrityPolicyComponent },
  { path: "**", redirectTo: "/home" }
];

export const AppRoutingModule = RouterModule.forRoot(routes, {
  useHash: false,
  preloadingStrategy: PreloadAllModules
});
