import { Treatment } from "../../models/Treatment";

export const treatments: Treatment[] = [
  {
    heading: "Behandlingar – ABC",
    content: ""
  },
  {
    heading: "Undersökning",
    content:
      "Vid undersökning görs en screening av dina tänder för att konrollera tandstatuset, fyra röntgenbilder ingår. Det vanligaste är att besöka tandvården en gång om året, men ibland är det bra att komma med kortare eller längre intervaller beroende på vårdbehovet. Undersökningen kan utföras av tandläkare eller tandhygienist."
  },
  {
    heading: "Fyllningsterapi",
    content:
      "”Hålen” lagas idag vanligtvis med komposit, ett plastmaterial. För stora fyllningar är oftast inte så hållbara och då kan inte alltid någon garanti utlovas. Man bör då överväga kronterapi, se Kronor"
  },
  {
    heading: "Tandstensborttagning / depuration",
    content:
      "Avlägsning av tandsten och rengörning av tandköttsfickor görs av tandhygienisten. Med hjälp av ultraljud och manuella skrapande instrument avlägsnas tandsten och bakterier som kan orsaka inflammation och tandlossning. Om man tycker att det är obehagligt kan man få bedövning vid besöket hos tandhygienisten."
  },
  {
    heading: "Bettskena",
    content:
      "Gnisslar man eller har problem med spänningshuvudvärk eller med käklederna kan ofta en bettskena hjälpa. En bettskena framställs med hjälp av ett avtryck som återger tändernas form så att en tandtekniker kan framställa en bettskena som är exakt anpassad efter tandraden. Det kan ta tid att vänja sig vid en bettskena, men efter en tid blir bettskenan ofta en mycket god hjälp för att kunna sova gott och skydda tänder, avlasta käkleder och käkmuskulatur. Ta alltid med bettskenan när du ska besöka din tandläkare."
  },
  {
    heading: "Extraktioner / Tanduttagningar",
    content:
      "Ibland är tänder så pass skadade att de inte går att behandla. En tanduttagning ska ej vara smärtsam om man har fått tillräckligt med bedövning. Man kan dock känna spänning och tryck, ibland knakar det vilket kan kännas obehagligt. Flerrotiga tänder delar man ofta på och avlägsnar en rot i taget för att behandlingen ska bli mer skonsam och minska risken att rötterna frakturerar. Ibland syr man ihop tandköttet efter en tandborttagning, men oftast kan man lämna det som det är. Det är viktigt att meddela vilka mediciner man tar innan en tandborttagning."
  },
  {
    heading: "Kronor",
    content:
      "När en tand har lagats för många gånger eller en stor del har gått sönder bör man göra en krona för att minska risken för nya frakturer. Vid kronterapi avlägsnas alla fyllningar och tanden beslipas till en cylinderform. Med ett avtryck återges formen så att tandteknikern kan framställa en krona som sedan cementeras fast på tanden. Porslin eller metall-keramik är de vanligaste materialen för kronor. Oftast har man 3 års garanti på kronor."
  },
  {
    heading: "Broar",
    content:
      "När man har förlorat en tand och önskar ersätta denna kan man göra en bro eller ”brygga”. Då beslipas granntänderna för att utgöra brostöd. Efter ett avtryck kan tandteknikern framställa en bro med två stöd som fäster på grantänderna och en ”hängande” tand som är gjuten till stöden. Man kan även ha flera hängande led om luckan omfattar fler tänder, då behöver man ibland också fler stödtänder."
  },
  {
    heading: "Implantat",
    content:
      "När en förlorad tand ska ersättas och granntänderna ej är tillräckligt starka eller för friska för att beslipas till en bro (se broar) är implantat ett bra alternativ. Innan man kan besluta om implantatbehandling är möjlig måste en noga undersökning av tandbenets kvalitet och volym göras, man bör också ta tillräckligt med röntgen för att undersöka om känsliga nerver befinner sig i närheten av det tänkta implantatsätet. Efter undersökningen utförs en liten operation då en titanskruv (=implantatet) placeras i käkbenet, efter några månaders läkning kan man sedan skruva fast en porslinstand på implantatet. Implantatbehandlingar har oftast god prognos, men det är viktigt att ta hand om sina implantat med tandtråd och mellanrumsborstar och även besöka tandhygienisten med jämna mellanrum för uppföljningar och kontroll. Man kan även utföra implantatbroar, då flera implantat opereras in och används som brostöd och hängande led kan placeras mellan implantaten (se broar)."
  },
  {
    heading: "Rotfyllningar",
    content:
      "Infektioner vid tändernas rötter kan uppstå av olika anledningar, ibland av djup karies, ibland av trauma och sprickor, ibland av bakterieläckage genom gamla fyllningar. Ibland har man inga symptom från infektionen och ibland får man stora smärtor. Det är dock viktigt att behandla infektioner så att den inte sprider sig och orsakar större besvär. När man gör en rotfyllning avlägsnas alla gamla fyllningar och man gör rent i rotkanalerna med hjälp av små filar och antibakteriella vätskor; en fyllning placeras i rotkanalen och därefter byggs tanden åter upp. Rotfyllda tänder blir ofta skörare och risken för större frakturer är stor, därför är kronterapi (se krona) att rekommendera efter en rotfyllning."
  },
  {
    heading: "Operationer",
    content:
      "När tänder inte kan avlägsnas på konventionellt sätt eller vid implantatinstallationer dukar man upp för operation. Då man öppnar och flyttar lite på tandköttet, samt borrar i käkbenet vid operationer, vill man undvika risken för infektion så mycket man kan. Patienten täcks med en steril duk, behandlande personal klär sig i sterila rockar/förkläden och handskar. Detta är för att åtstadkomma så bra resultat som möjligt för behandlingen och minimera efterbesvär för patienten."
  },
  {
    heading: "Proteser/ ”Löständer”",
    content:
      "Ibland är tänderna så pass skadade och benkvaliteten är inte tillräcklig för att kunna installera implantat då är proteser ett alternativ. Man kan ersätta alla tänder eller ibland bara fylla ut större tandluckor med proteser."
  }
];
