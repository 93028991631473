<section class="section-about">
    <div class="u-center-text">
        <h2 class="heading-secondary">
            Välkommen
        </h2>
    </div>
    <div class="row">
        <div class="col-1-of-2">
            <div class="text__wrapper">
                <h3 class="u-margin-bottom-small">Hos oss är alla välkomna - stora som små!</h3>
                <p class="paragraph">Vi utför allt från bettutvecklingskontroller på de yngsta patienterna till
                    avancerade
                    bettrehabiliteringar med bland annat implantat.</p>
                <p class="paragraph">Kliniken är belägen centralt i Karlskrona. Det har varit tandläkarmottagning här
                    sedan
                    huset bygges på 60-talet men lokalen är nyrenoverad och har modern utrustning.</p>
                <p class="paragraph">Vårt mål är att alla som besöker oss ska känna sig trygga och få en högkvalitativ
                    vård
                    som är anpassad efter varje enskild patient.</p>
                <p class="paragraph">Vi är anslutna till Privattandläkarna och Vårdföretagarna, samt medlemmar i
                    Sveriges
                    Tandläkarförbund.</p>
            </div>
        </div>
        <div class="col-1-of-2">
            <div class="composition">
                <div alt="Photo 1" class="composition__photo composition__photo--p1"></div>
                <div alt="Photo 2" class="composition__photo composition__photo--p2"></div>
                <div alt="Photo 3" class="composition__photo composition__photo--p3"></div>
            </div>
        </div>
    </div>
</section>