import { OperationalDay } from "../../models/OperationalDay";

export const TIME_TABLE: OperationalDay[] = [
  { day: "Måndag", time: "07:30 - 16:00" },
  { day: "Tisdag", time: "07:30 - 18:00" },
  { day: "Onsdag", time: "07:30 - 16:00" },
  { day: "Torsdag", time: "07:30 - 16:00" },
  { day: "Fredag", time: "07:30 - 13:00" },
  { day: "Lunchstängt", time: "12:30 - 13:15" }
];
