<div class="navigation">
    <input type="checkbox" class="navigation__checkbox" id="navi-toggle" [(ngModel)]="toggled">
    <label for="navi-toggle" class="navigation__button" (click)="toggle()">
        <span class="navigation__icon">&nbsp;</span>
    </label>
    <div class="navigation__background">&nbsp;</div>
    <nav class="navigation__nav">
        <ul class="navigation__list">
            <li class="navigation__item">
                <a href="" class="navigation__link" [routerLinkActive]="['link-active']" [routerLink]="['/hem']" (click)="uncheck(); scrollTop()">
                    <span></span>Hem</a>
            </li>
            <li class="navigation__item">
                <a href="" class="navigation__link" [routerLinkActive]="['link-active']" [routerLink]="['/behandlingar']" (click)="uncheck(); scrollTop()">
                    <span></span>Behandlingar</a>
            </li>
            <li class="navigation__item">
                <a href="" class="navigation__link" [routerLinkActive]="['link-active']" [routerLink]="['/oppettider']" (click)="uncheck(); scrollTop()">
                    <span></span>Kontakt/Öppettider</a>
            </li>
            <li class="navigation__item">
                <a href="" class="navigation__link" [routerLinkActive]="['link-active']" [routerLink]="['/om-oss']" (click)="uncheck(); scrollTop()">
                    <span></span>Om oss</a>
            </li>
            <li class="navigation__item">
                <a href="" class="navigation__link" [routerLinkActive]="['link-active']" [routerLink]="['/priser']" (click)="uncheck()">
                    <span></span>Priser</a>
            </li>
            <li class="navigation__item">
                <a href="" class="navigation__link" [routerLinkActive]="['link-active']" [routerLink]="['/tidsbokning']" (click)="uncheck()">
                    <span></span>Boka tid</a>
            </li>         
        </ul>
    </nav>
</div>