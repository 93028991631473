import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app.routes";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./shared/components/navbar/navbar.component";
import { HomeComponent } from "./pages/home/home.component";
import { HomeHeaderComponent } from "./pages/home/sections/header/header.component";
import { HomeAboutComponent } from "./pages/home/sections/about/about.component";
import { HomeFeaturesComponent } from "./pages/home/sections/features/features.component";
import { SafeHtmlPipe } from "./shared/pipes/safehtml.pipe";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { OpenHoursComponent } from "./pages/open-hours/open-hours.component";
import { TreatmentComponent } from "./pages/treatment/treatment.component";
import { HomeNewsComponent } from "./pages/home/sections/news/news.component";
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PricesComponent } from "./pages/prices/prices.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewsService } from './services/news.service';
import { DataAccessService } from './services/data-access.service';
import { BookingComponent } from './pages/booking/booking.component';
import { MyPagesComponent } from './pages/my-pages/my-pages.component';
import { IntegrityPolicyComponent } from './pages/integrity-policy/integrity-policy.component';
import { LocationStrategy, PathLocationStrategy } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    NavbarComponent,
    HomeComponent,
    HomeHeaderComponent,
    HomeAboutComponent,
    HomeFeaturesComponent,
    HomeNewsComponent,
    FooterComponent,
    OpenHoursComponent,
    TreatmentComponent,
    AboutUsComponent,
    PricesComponent,
    BookingComponent,
    MyPagesComponent,
    IntegrityPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [DataAccessService, NewsService, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
