import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { INews } from '../models/INews';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private _baseUrl: string;

  constructor(private _http: HttpClient) {
    this._baseUrl = "example.com";
  }

  getNews(): Observable<INews> {
    return this._http
      .get<INews>(`${window.location.protocol + "//" + window.location.host}/api.php`);
  }
}
