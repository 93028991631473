import { Component, OnInit } from "@angular/core";
import { OperationalDay } from "../../models/OperationalDay";
import { TIME_TABLE } from "../../shared/static/time-table";

@Component({
  selector: "app-open-hours",
  templateUrl: "./open-hours.component.html",
  styleUrls: ["./open-hours.component.scss"]
})
export class OpenHoursComponent implements OnInit {
  timeTable: OperationalDay[] = [];

  constructor() {}

  ngOnInit() {
    this.initTimeTable();
  }

  initTimeTable() {
    this.timeTable = TIME_TABLE;
  }
}