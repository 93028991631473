<div class="booking u-padding-top-small">
    <div class="u-padding-top-large">
        <div class="row">
            <div class="booking__content u-box-shadow">
                <h2 class="heading-secondary u-center-text">Tidsbokning</h2>
                <p>Ni når tidsbokningen via knappen nedan.</p>
                <br />
                <p>
                    Beakta att alla lediga tider EJ finns synliga i tidsbokningsmodulen. Därför kan det vara bra att
                    ringa eller maila om man inte hittar en passande tid.
                </p>
                <p>Nya patienter ber vi kontakta oss på telefon
                    eller mail så att vi bättre kan planera in det första besöket.
                </p>

                <p class="u-margin-top-tiny">Tel: <a href="tel:0455-18714">0455-18714</a></p>
                <a href="mailto:info@tandlakarehjalmarsson.se">info@tandlakarehjalmarsson.se</a>

                <p class="u-margin-top-tiny special_typography">
                    Vid akuta besvär under helger eller vid de tillfällen som vi ej finns
                    tillgängliga, nås jourhavande tandläkare via sjukhusets växel på telefonnumret
                    <a href="tel:0455-731000">0455-731000</a>
                    .
                </p>

                <div class="booking__buttons u-center-text u-margin-top-small">
                    <a href="https://5018.etand.se/bookings/select-treatment-type" target="_blank">
                        <button class="btn btn--primary">Boka tid</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>