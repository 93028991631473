<div class="policy">
    <div class="row">
        <div class="policy__content">
            <h1 class="policy--heading">Integritetspolicy (Tandläkare Josefine Hjalmarsson AB)</h1>
            <p>Vi värnar om våra kunders och användares integritet och det är viktigt för oss att alla känner sig trygga
                när
                man
                använder våra tjänster och besöker våra sidor.</p>

            <h2 class="policy--heading">Om GDPR</h2>
            <p>GDPR är Europas data och integritetslag som etablerades 2018. Ett av huvudsyftena med GDPR är att skydda
                individers
                integritet och rättigheter vad gäller personlig information.<br />
                I denna policy berättar vi bland annat om hur vi hanterar personlig information, vilka rättigheter du
                som
                interagerar med oss har och vad du kan göra ifall du vill veta mer.</p>

            <h2 class="policy--heading">Definitioner</h2>

            <ul>
                <li>Företag syftar till Tandläkare Josefine Hjalmarsson AB (Org. 559243-8104)</li>
                <li>“Du”, “dig, “din” betyder den individ, organisation eller laglig enhet vars uppgifter vi behandlar
                </li>
                <li>“Vi”,”oss”,”vår” syftar till Företag</li>
                <li>Webbplats hemsidor/webbplatser som ägs av Företag</li>
                <li>Cookies är små filer som lagras i din enhet i olika syften</li>
                <li>Enhet syftar till exempelvis mobiltelefon, dator eller laptop</li>
                <li>Personuppgifter är all information som direkt eller indirekt kan kopplas till en specifik individ
                </li>
                <li>Behandling är allt som händer och sker. Exempelvis behandling av personuppgifter syftar till allt
                    som
                    händer
                    med
                    en personuppgift.</li>
                <li>Personuppgiftsansvarig är den organisation, stiftelse, förening eller myndighet som bestämmer för
                    vilka
                    ändamål
                    personuppgifter ska behandlas och hur (IMY)</li>
                <li>Personuppgiftsbiträde är den som behandlar personuppgifter för den personuppgiftsansvariges räkning
                </li>
                <li>Tredje part betyder en annan person, organisation, stiftelse, förening, webbsida, program, tjänst
                    eller
                    företag
                    av någon form</li>
            </ul>
            <p>Övriga begrepp i denna policy har samma innebörd som anges i art. 4 i GDPR.
                Vid skiljaktigheter går definitioner i GDPR först.</p>

            <h2 class="policy--heading">Vem är ansvarig för behandlingen av personuppgifter?</h2>
            <p>Om vi bestämmer och har möjligheten att bestämma hantering av personuppgifter är vi att anse som
                Personuppgiftsansvariga. I de fall där vi inte kan bestämma hur personuppgifter hanteras av exempelvis
                tredje
                part,
                har vi inget juridiskt ansvar för.
                Vi har gjort vårt yttersta för att samla deras information nedan där du kan läsa hur de hanterar
                personuppgifter.
            </p>
            <h2 class="policy--heading">Vilka typer av personuppgifter behandlar vi?</h2>
            <p>Vi gör vårt yttersta för att arbeta enligt uppgiftsminimering och enbart behandla personuppgifter som är
                nödvändiga
                för att ge dig som besökare, kund eller registrerad en så bra upplevelse som möjligt och uppfylla vår
                tjänst
                till
                dig. Samtidigt som vi ämnar att uppfylla de ändamål som de insamlade uppgifterna blev insamlade för.</p>

            <h3 class="policy--heading">Anonyma icke-identifierbara personuppgifter</h3>
            <p>Vi samlar in anonyma uppgifter vid besök och användning av vår webbplats i syfte att förbättra
                kundupplevelsen
                och
                utveckla våra erbjudanden och tjänster. Anonyma uppgifter är inte på individnivå och kan vara saker så
                som
                antalet
                besök på webbplatsen, klick på knappar, geografisk belägenhet, besökslängd, besök på olika sidor och
                formulär
                samt
                typ av enhet eller webbläsare som används, med mera.</p>

            <h3 class="policy--heading">Identifierbara personuppgifter</h3>

            <p>Vanligtvis behöver du inte avslöja din identitet för att använda eller utnyttja vår sida men i de fall
                där du
                vill
                bli kontaktad, begärt återkoppling eller anmält dig till ett event eller liknande kan vi komma att
                behandla
                dina
                personuppgifter.<br />
                Främst nedan angivna typer av personuppgifter är de uppgifter som vi kan få tillgång till när du
                kontaktar
                oss,
                ingår ett avtal, eller i övrigt samband med utförande av våra tjänster eller ett uppdrag som vi har
                blivit
                anlitade
                att utföra:<br />
                Namn, personnummer, telefon, e-post, adress, användar-ID, organisation/arbetsställe, titel eller övriga
                personuppgifter.</p>

            <h2 class="policy--heading">Vilka personuppgifter samlar vi in?</h2>
            <p>Vi kan samla in olika typer av personuppgifter när du interagerar med oss eller våra tjänster på olika
                sätt.
                Det
                är
                upp till dig vilken information du vill göra tillgänglig för oss och när.</p>

            <h3 class="policy--heading">När du besöker vår webbplats</h3>
            <p>När du besöker vår webbplats kan vi komma att behandla personuppgifter, beroende på vilka uppgifter du
                väljer
                att
                dela med dig utav. Den information som samlas in vid användning av webbplatsen sker enbart vid ditt
                godtycke.
                Mer information hittar du i vår cookie policy (se kugghjulet på hemsidan).</p>

            <h3 class="policy--heading">När du fyller i ett formulär</h3>
            <p>När du väljer att fylla i ett formulär kan vi få tillgång till personuppgifter som används för att
                interagera
                med
                dig, besvara frågor och rendera tjänster du efterfrågat. Information som kan samlas in:</p>


            <ul>
                <li>Namn</li>
                <li>Telefonnummer</li>
                <li>E-postadress</li>
                <li>Övrig information du väljer att dela med dig utav</li>
            </ul>

            <p><i>Grund för behandling: Samtycke</i></p>

            <h3 class="policy--heading">Uppgifter vi får från tredje part</h3>

            <p>
                Vi kan använda oss av marknadsföringsmedel i form av ex Google eller Facebook (se kompletterande
                information
                under
                rubriken om marknadsföring). Dessa plattformar kan komma att samla in olika typer av information för att
                fungera
                korrekt och ge oss möjlighet att kostnadseffektivt exponera vårt varumärke och våra tjänster. Nedan
                uppgifter
                kan
                samlas in i marknadsföringssyfte.

            </p>

            <ul>
                <li>Klick ID</li>
                <li>Enhetstyp (dator, mobil eller laptop)</li>
                <li>Webbläsare</li>
                <li>Element ID (knappar, rutor, bilder eller element som webbplatsen är skapad med)</li>
                <li>Köpprocess (vilka webbsidor som är besökta innan ett köp/samtal)</li>
                <li>Knapptryck</li>
                <li>Val av produkter och tjänster</li>
                <li>Sidbesök</li>
                <li>Sidnyttjande (exempelvis hur stor andel av hemsidan som nyttjas)</li>
            </ul>

            <p><i>Grund för behandling: Berättigat intresse.</i></p>

            <p>Dessa uppgifter analyserar vi för att genomföra bättre marknadsföring och möjlighet att kunna ge en så
                bra
                användarupplevelse som möjligt genom att kunna skapa webbsidor som våra besökare vill använda. Eftersom
                marknadsföring kan justeras över tid och likaså de uppgifter som samlas in så kan utsträckningen över
                vilka
                uppgifter som samlats in förändras över tid.</p>

            <h2 class="policy--heading">Hur länge sparas personuppgifter och var?</h2>
            <p>Vi gör det vi kan för att enbart spara de uppgifter vi behöver i den längd som de behövs för. Sparar
                därför
                enbart i
                upp till 36 månader om inte andra krav ställs i form av lagar eller om personen/entiteten fortsätter att
                interagera
                och kommunicera med oss vilket kan förnya insamlandet.<br />
                Vi gör vårt yttersta för att följa de lagar och regler som ställs på säkerhet och konfidentialitet och
                strävar
                därför efter att enbart lagra information inom EU/EES. Om uppgifter lagras utanför gör vi det vi kan för
                att
                säkerhetsställa att rätt säkerhet och konfidentialitet upprätthålls.</p>

            <h2 class="policy--heading">Hur delar vi med oss av personuppgifter?</h2>
            <p>
                Vi kommer aldrig att sälja dina uppgifter till tredje part men vi kan komma att behöva dela
                personuppgifter
                för
                att
                kunna erbjuda dig bästa möjliga service, ge dig en bättre upplevelse eller följa lagar, regler, skydda
                våra
                intressen och utveckla vår verksamhet i marknadsföringssyfte.
                Vid delning av personuppgifter ingår vi i ett personuppgiftsbiträdesavtal.
                Exempel på tjänsteleverantörer och tredje part är leverantörer av:
            </p>

            <ul>
                <li>CRM-System</li>
                <li> Marknadsföringsföretag och agenturer </li>
                <li>Redovisningskonsulter</li>
                <li>Dokumenthanteringssystem, med mera.</li>
            </ul>

            <h2 class="policy--heading">Uppgifter som samlas in i marknadsföringssyfte</h2>
            <h3 class="policy--heading">Observera att verktygen som listas i följande underavsnitt kanske inte används konstant.</h3>
            <p>Vi använder oss utav annons-plattformar i marknadsföringssyfte för att nå ut till nya potentiella kunder
                för
                att
                marknadsföra, informera om och utveckla våra tjänster och vårt företag. Det är viktigt för oss att vår
                annonsering
                är relevant och intresserar de vi annonserar till och därför kan vi samla in information om klick och
                åtgärder
                på
                vår webbsida.</p>

            <h3 class="policy--heading">Google Ads</h3>
            <p>
                Google använder sig av annonsservrar som levererar annonser, dessa servrar använder sig av
                annonsserver-cookies
                som
                hjälper till att inhämta information om placeringar och klick från besökare. När du klickat på en annons
                kan
                Google
                lagra en cookie på din enhet. Viss information så som:</p>
            <ul>
                <li>Cookie ID</li>
                <li>Antalet annonsvisningar</li>
                <li>Senaste visning (för spårning av konverteringar)</li>
                <li>Opt-out information</li>
            </ul>
            <p>
                Tack vare dessa cookies kan Google känna igen din webbläsare för att få reda på vilken annons som
                resulterade i
                webbläsarens besök på vår webbplats.
            </p>
            <p>Vi samlar inte in någon personlig data utan får enbart tillgång till statistik från Google och kan inte i
                vår
                annonsering identifiera en specifik individ med denna information som enbart hjälper oss att ta reda på
                framgångsrik
                marknadsföring och förbättra vår annonsering. Vi har inget inflytande på omfattningen av vad Google
                samlar
                in
                för
                sina kunder eller för egen del.</p>

            <h2 class="policy--heading">Opt-out från Google annonsering</h2>
            <p>Du kan hos Google välja att frånsäga dig skräddarsydd eller personlig annonsering <a
                    href="https://myadcenter.google.com/" target="_blank">här</a>.<br />
                Du kan välja att frånsäga till marknadsföring från tredjepart <a href="https://thenai.org/opt-out/"
                    target="_blank">här</a>.<br />
                Du kan kontrollera vilka inställningar för din enhet <a
                    href="https://support.google.com/My-Ad-Center-Help/answer/12155656#mob"
                    target="_blank">här</a>.<br />
                Du kan frånsäga dig på vår webbplats. Mer information i vår Cookie Policy.</p>

            <h2 class="policy--heading">Facebook & Meta</h2>

            <p>Vårt företag använder sig av Facebooks Pixel och Conversions API från Facebook/Meta Platforms. Facebook
                Pixel
                används
                i
                följande ändamål:</p>
            <h3 class="policy--heading">Facebook Fan Page</h3>
            <p>Vårt företag är personuppgiftsansvarig för personlig data insamlad från Facebook Fan Pages när den
                används
                utanför
                Facebook. Som ägare av sidan agerar vi tillsammans med Facebook som delade personuppgiftsansvariga.</p>
            <p><a href="https://www.facebook.com/privacypolicies"
                    target="_blank">https://www.facebook.com/privacypolicies</a>
            </p>
            <p><a href="https://www.facebook.com/terms" target="_blank">https://www.facebook.com/terms</a></p>
            <p><a href="https://www.facebook.com/policy" target="_blank">https://www.facebook.com/policy</a></p>

            <h2 class="policy--heading">Vilka säkerhetsåtgärder kan vidtas av oss?</h2>
            <p>För att göra vårt yttersta för att skydda din integritet och personuppgifter vidtar vi olika
                säkerhetsåtgärder.
                All
                tillgång till personuppgifter är skyddat med lösenord.</p>

            <p>Organisatoriska säkerhetsåtgärder:</p>
            <ul>
                <li>Rutiner</li>
                <li>Instruktioner</li>
            </ul>

            <h2 class="policy--heading">Vilka är dina rättigheter enligt GDPR?</h2>
            <p>Vi tror på att ge alla våra besökare och kunder lika information och rättigheter.
                Nedan är de rättigheter du har enligt GDPR vad gäller dina personuppgifter:</p>

            <ul>
                <li>Rätten att få information</li>
                <li>Rätt att få tillgång till uppgifter</li>
                <li>Rätt att få rättelse av uppgifter</li>
                <li>Rätten till radering</li>
                <li>Rätten till begränsning av behandling</li>
                <li>Rätten till dataportabilitet</li>
                <li>Rätten att invända</li>
                <li>Rätten att undvika automatiskt beslutsfattande</li>
            </ul>

            <p>Vissa av rättigheterna gäller enbart i vissa situationer varvid vi gör vårt yttersta för att uppfylla
                dina
                önskemål
                när de är lagligt och möjligt för oss.<br />
                Du har rätt att åberopa dina rättigheter när du vill och du har rätt att begära ett registerutdrag
                vilket
                sker
                utan
                kostnad.<br />
                För att åberopa en av dina rättigheter behöver du kunna identifiera dig tydligt för att undvika att
                begära
                uppgifter
                från annan person. För att skydda personuppgifterna är det viktigt för oss att du ger oss tillräckligt
                med
                underlag
                för att vi skall kunna göra en bedömning nog för att avgöra att du är du.</p>

            <h2 class="policy--heading">Uppdateringar av denna policy</h2>
            <p>Vi kan komma att uppdatera denna policy när vi anser att det behövs på grund utav exempelvis
                lagförändringar
                eller
                andra externa/interna förändringar som kräver att vi behöver uppdatera delar eller hela policyn.<br />
                Policyns uppdateringar kan ske vid behov och utan information eller föregående information om ändringar.
                Se
                därför
                till att besöka denna sida regelbundet för att hålla dig uppdaterad och informerad om de senaste
                ändringarna. Du
                ansvarar själv för att läsa igenom och ta till dig vår integritetspolicy och hålla dig uppdaterad.</p>

            <h2 class="policy--heading">Vid inträffade personuppgiftsincidenter</h2>
            <p>Om det skulle inträffa en incident relaterat personuppgifter där vi anses vara personuppgiftsansvariga
                kommer
                vi
                att
                anmäla detta till Integritetsmyndigheten (IMY).<br />
                Om vi anser att du behöver informeras kommer du att få information via tillgänglig kommunikationskanal.
            </p>

            <h2 class="policy--heading">Kontakta oss</h2>
            <p>Vi gör vårt yttersta för att bistå dig med den information du söker så kontakta oss gärna om du har
                tankar
                eller
                funderingar kring hur vi hanterar personuppgifter.<br />
                Observera att beroende på förfråga så kan vi komma att behöva styrka din identitet för att undvika att
                personuppgifter hamnar i fel händer.
            </p>
            <p>Vi försöker svara på legitima önskemål inom en månad.</p>

            <p>Nedan hittar du kontaktuppgifter gällande personuppgifter:</p>

            <ul>
                <li>E-post: josefine@tandlakarehjalmarsson.se</li>
                <li>Postadress: N Kungsg 12, 37133 karlskrona</li>
            </ul>

            <p>
                Du har även rätt att kontakta den svenska tillsynsmyndigheten för att lämna klagomål.<br />
                Integritetsskyddsmyndigheten (IMY)<br />
                Integritetsskyddsmyndigheten, Box 8114, 104 20 Stockholm.<br />
                Telefon: 08-657 61 00.
            </p>

            <p>E-post: imy@imy.se</p>
        </div>
    </div>
</div>