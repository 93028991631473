<div class="my-pages u-padding-top-small">
    <div class="u-padding-top-large">
        <div class="row">
            <div class="my-pages__content u-box-shadow">
                <h2 class="heading-secondary u-center-text">Mina sidor</h2>
                <p>Mina sidor når ni via knappen nedan.</p>
                <div class="my-pages__buttons">
                    <a href="https://5018.etand.se/login" target="_blank">
                        <button class="btn btn--primary">Mina sidor</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>