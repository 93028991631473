import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { employees } from '../shared/static/employees';
import { Employee } from '../models/Employee';
import { Treatment } from '../models/Treatment';
import { treatments } from '../shared/static/treatsments';

@Injectable({
  providedIn: 'root'
})
export class DataAccessService {
  constructor() { }

  scrollTop() {
    window.scrollTo(0,0);
  }

  getEmployees(): Observable<Employee[]> {
    return of(employees);
  }

  getTreatsments(): Observable<Treatment[]>{ 
    return of(treatments);
  }
}
