<div class="about">
  <div class="u-padding-top-large">
    <div class="row">
      <div class="about__head">
        <h2 class="heading-secondary u-center-text">Om oss</h2>
        <img src="assets/images/2022/team.min.jpg" />
        <div class="about__head__text">
          <p class="paragraph">
            Kliniken har en lång historia då det varit tandläkarmottagning i
            lokalen sedan huset byggdes på 60-talet, flera tandläkare har
            passerat och ännu fler tänder har behandlats.
          </p>
          <p class="paragraph">
            I maj 2018 övertog tandläkare Josefine Hjalmarsson kliniken av
            tandläkarparet Johan och Liselott Flyborg som utfört ett stort
            arbete med att miljöcertifiera kliniken och döpt den till ”Den Gröna
            Praktiken”.
          </p>
          <p class="paragraph">
            Vi fortsätter med miljöarbetet och moderniseringen av kliniken, men
            med stort fokus på att den ska förbli personlig och hemtrevlig.
          </p>
          <p class="paragraph">
            Kliniken är en kvalitetssäkrad klinik vilket innebär att vi arbetar
            med kvalitets- och patientsäkerhetsfrågor på ett aktivt och
            systematiskt sätt. Vi änvänder oss av
            <a href="https://www.almasoft.se/tls/qdent" target="_blank">
              Qdent
            </a>
            - ett webbaserat kvalitetssäkringssystem speciellt anpassat för
            tandvården.
          </p>
          <p class="paragraph">
            Vi är anslutna till Privattandläkarna och
            <a href="https://www.vardforetagarna.se/" target="_blank">
              Vårdföretagarna
            </a>
            , samt medlemmar i Sveriges Tandläkarförbund.
          </p>
          <p class="small-text">
            För mer information:
            <a href="https://www.allabolag.se/" target="_blank">
              www.allabolag.se/start
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row u-margin-top-small">
    <div class="about__employee-wrapper u-margin-bottom-medium u-box-shadow" *ngFor="let employee of employees">
      <figure class="about__employee-wrapper__shape">
        <div class="about__employee-wrapper__photo__wrapper">
          <img class="about__employee-wrapper__photo" src="{{ employee.imageUrl }}" alt="{{ employee.name }}" />
        </div>
      </figure>
      <div class="about__employee-wrapper__text">
        <h3 class="heading-tertiary">{{ employee.occupation }}</h3>

        <ul class="about__employee-wrapper__list">
          <li class="about__employee-wrapper__sublist__name">
            {{ employee.name }}
          </li>
          <li class="about__employee-wrapper__sublist">
            {{ employee.customItemOne }}
          </li>
          <li class="about__employee-wrapper__sublist">
            {{ employee.customItemTwo }}
          </li>
          <li class="about__employee-wrapper__sublist">
            {{ employee.customItemThree }}
          </li>
          <li class="about__employee-wrapper__sublist" [innerHTML]="employee.customItemFour | safeHtml"></li>
        </ul>
      </div>
    </div>
  </div>
</div>