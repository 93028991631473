import { Component, OnInit } from "@angular/core";
import { DataAccessService } from "../../../services/data-access.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  date: string;
  constructor(private _dataAccess: DataAccessService) {}

  ngOnInit() {
    this.date = new Date().getFullYear().toString();
  }

  scrollTop() {
    this._dataAccess.scrollTop();
  }
}
