import { Component, OnInit, AfterViewInit } from "@angular/core";
import { DataAccessService } from "../../../services/data-access.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  toggled: boolean = false;
  delay: number;

  constructor(private _dataAccess: DataAccessService) {}

  ngOnInit() {
    this.delay = 500;
  }

  ngAfterViewInit() {
    setTimeout(() => {}, this.delay);
  }

  scrollTop() {
    this._dataAccess.scrollTop();
  }

  toggle() {
    if (this.toggled) {
      this.setDisplay("none", this.delay);
    } else {
      this.setDisplay("inline-block", 0);
    }
  }

  setDisplay(display, delay) {
    let links = document.getElementsByClassName("navigation__link");

    setTimeout(() => {
      for (let i = 0; i < links.length; i++) {
        links[i].setAttribute("style", "display:" + display + "");
      }
    }, delay);
  }

  uncheck() {
    this.toggled = false;
    this.setDisplay("none", this.delay);
  }
}
