<div class="open-hours u-padding-top-large">
    <div class="open-hours__content">
        <div class="open-hours__time-table u-box-shadow">
            <h2 class="heading-secondary u-center-text open-hours__time-table__header">Öppettider</h2>
            <hr />
            <div class="open-hours__time-table__item" *ngFor="let item of timeTable">
                <div class="open-hours__time-table__lhs" *ngIf="item.day">{{ item.day }}</div>
                <div class="open-hours__time-table__lhs" *ngIf="!item.day">&nbsp;</div>
                <div class="open-hours__time-table__rhs">{{ item.time }}</div>
            </div>
            <div class="open-hours__time-table__description u-margin-top-small">
                Receptionen är bemannad mån-tors 7.30 -16.
                <br/> 
                Övriga tider går det bra att lämna ett meddelande så återkommer vi så fort vi kan.
            </div>
        </div>

        <div class="open-hours__contact u-box-shadow u-margin-top-small">
            <h2 class="heading-secondary u-center-text open-hours__time-table__header">Kontakt</h2>
            <hr />
            <div class="open-hours__contact__text u-center-text">
                <p class="paragraph">Tandläkare Josefine Hjalmarsson</p>
                <p class="paragraph">Norra Kungsgatan 12</p>
                <p class="paragraph">371 33 Karlskrona</p>
                <p class="paragraph">Telefon: <a href="tel:0455-18714">0455-18714</a></p>
                <div class="open-hours__contact__text--link">
                    <a href="mailto:info@tandlakarehjalmarsson.se">info@tandlakarehjalmarsson.se</a>
                </div>
            </div>
        </div>
    </div>
</div>