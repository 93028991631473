import { Employee } from "../../models/Employee";

export const employees: Employee[] = [
  {
    name: "Josefine Hjalmarsson",
    imageUrl: "/assets/images/2019/min/josefine.jpg",
    occupation: "Tandläkare",
    customItemOne: "Ägare av kliniken.",
    customItemTwo: "Utbildad i Malmö med examen 2013.",
    customItemThree:
      "Har jobbat på folktandvården och på Tandvårdshögskolans käkirurgiska avdelning.",
    customItemFour: "Älskar utmaningar 😄",
  },
  {
    name: "Anna Rosenberg",
    imageUrl: "/assets/images/2019/min/anna.jpg",
    occupation: "Tandhygienist",
    customItemOne: "Utbildad i Örebro med examen 1997.",
    customItemTwo: "Spelar gärna tennis.",
    customItemThree: "Händig hemmafixare.",
    customItemFour: "Hundälskare 🐕"
  },
  {
    name: "Eva Andersson",
    imageUrl: "/assets/images/2019/min/eva.jpg",
    occupation: "Receptionist",
    customItemOne: "Tidigare banktjänsteman.",
    customItemTwo: "Älskar skärgården och att vandra i naturen.",
    customItemThree: "Målar, stickar och väver 🎨",
    customItemFour: "&nbsp;"
  },
  {
    name: "Lotta Vestlund",
    imageUrl: "/assets/images/2019/min/lotta.jpg",
    occupation: "Tandsköterska",
    customItemOne: "Utbildad i Karlskrona 2018.",
    customItemTwo: "Ligger steget före.",
    customItemThree: "Säger sällan nej till en fika 🍰",
    customItemFour: "&nbsp;"
  },
  {
    name: "Paula Fröberg",
    imageUrl: "/assets/images/2020/paula.min.jpg",
    occupation: "Tandläkare",
    customItemOne: "Utbildad i Köpenhamn med examen 2014.",
    customItemTwo: "Har jobbat på FTV Ronneby, samt på sjukhustandvården.",
    customItemThree: "Fullfjädrad kock och gourmand 🥕",
    customItemFour: "&nbsp;"
  },
  {
    name: "Vicky Henriquez-Moberg",
    imageUrl: "/assets/images/2022/vicky.min.jpg",
    occupation: "Tandsköterska ",
    customItemOne: "Utbildad i Karlskrona, examen 2019.",
    customItemTwo: "Höjer musiken i slutet av arbetsdagen och missar sällan en fest 🎶",
    customItemThree: "",
    customItemFour: "&nbsp;"
  },
  {
    name: "Linn Visser",
    imageUrl: "/assets/images/2022/linn.min.jpg",
    occupation: "Tandsköterska",
    customItemOne: "Utbildad i Karlskrona, examen 2021",
    customItemTwo: "När solen skiner hittar man henne på segelbåten 🌞",
    customItemThree: "",
    customItemFour: "&nbsp;"
  },
  {
    name: "Ebba Fröberg",
    imageUrl: "/assets/images/2022/ebba.min.jpg",
    occupation: "Lokalvård",
    customItemOne: "Liten och nitisk.",
    customItemTwo: "Enligt egen utsago en inbiten datanörd 😎",
    customItemThree: "",
    customItemFour: "&nbsp;"
  }
];
