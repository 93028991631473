<section class="section-features">
    <div class="row">
        <div class="col-1-of-4">
            <a class="feature__link" [routerLinkActive]="['link-active']" [routerLink]="['/behandlingar']"
                (click)="scrollTop()">
                <div class="feature-box">
                    <i class="feature-box__icon large material-icons">apps</i>
                    <h3 class="heading-tertiary u-margin-bottom-small">Behandlingar</h3>
                </div>
            </a>
        </div>

        <div class="col-1-of-4">
            <a class="feature__link" [routerLinkActive]="['link-active']" [routerLink]="['/oppettider']"
                (click)="scrollTop()">
                <div class="feature-box">
                    <i class="feature-box__icon large material-icons">contact_phone</i>
                    <h3 class="heading-tertiary u-margin-bottom-small">Kontakt/Öppettider</h3>
                </div>
            </a>
        </div>

        <div class="col-1-of-4">
            <a class=" feature__link " [routerLinkActive]="[ 'link-active']" [routerLink]="[ '/om-oss']"
                (click)="scrollTop()">
                <div class="feature-box">
                    <i class="feature-box__icon large material-icons">sentiment_very_satisfied</i>
                    <h3 class="heading-tertiary u-margin-bottom-small">Om oss</h3>
                </div>
            </a>
        </div>

        <div class="col-1-of-4">
            <a class="feature__link" [routerLinkActive]="['link-active']" [routerLink]="['/priser']"
                (click)="scrollTop()">
                <div class="feature-box">
                    <i class="feature-box__icon large material-icons">list_alt</i>
                    <h3 class="heading-tertiary u-margin-bottom-small">Priser</h3>
                </div>
            </a>
        </div>
    </div>
     <div class="row">
        <div class="col-1-of-4">&nbsp;</div>
        <div class="col-2-of-4">
            <a class="feature__link" [routerLinkActive]="['link-active']" [routerLink]="['/tidsbokning']"
                (click)="scrollTop()">
                <div class="feature-box feature-box--small">
                    <i class="feature-box__icon large material-icons">access_time</i>
                    <h3 class="heading-tertiary u-margin-bottom-small">Boka tid</h3>
                </div>
            </a>
        </div>        
    </div>
    <div class="col-1-of-4">&nbsp;</div>
</section>