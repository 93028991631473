<footer class="footer">
    <div class="footer__logo-box">
        <img class="footer__logo-image" srcset="assets/images/2020/svg/02a_logo_farg_mork-bg.svg" alt="Logo"
            src="assets/images/PT_medium-min.png">
    </div>
    <div class="footer__navigation u-center-text">
        <ul class="footer__list">
            <li class="footer__item">
                <a href="" class="footer__link" [routerLinkActive]="['link-active']" [routerLink]="['/behandlingar']"
                    (click)="scrollTop()">
                    <span></span>Behandlingar</a>
            </li>
            <li class="footer__item">
                <a href="" class="footer__link" [routerLinkActive]="['link-active']" [routerLink]="['/oppettider']"
                    (click)="scrollTop()">
                    <span></span>Kontakt/Öppettider</a>
            </li>
            <li class="footer__item">
                <a href="" class="footer__link" [routerLinkActive]="['link-active']" [routerLink]="['/om-oss']"
                    (click)="scrollTop()">
                    <span></span>Om oss</a>
            </li>
            <li class="footer__item">
                <a href="" class="footer__link" [routerLinkActive]="['link-active']" [routerLink]="['/priser']"
                    (click)="scrollTop()">
                    <span></span>Priser</a>
            </li>
            <li class="footer__item">
                <a href="" class="footer__link" [routerLinkActive]="['link-active']" [routerLink]="['/integritetspolicy']"
                    (click)="scrollTop()">
                    <span></span>Integritetspolicy</a>
            </li>
        </ul>
        <p class="paragraph">Copyright &#9400; - Josefine Hjalmarsson {{ date }}</p>
    </div>
</footer>