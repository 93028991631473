<header class="header">
    <div class="header__logo-box">
        <img src="assets/images/clean_logo.svg" alt="Logo" class="header__logo">
    </div>
    <div class="header__text-box">
        <h1 class="heading-primary">
            <span class="heading-primary--main">Tandläkare</span>
            <span class="heading-primary--sub">Josefine Hjalmarsson</span>
        </h1>
    </div>
</header>