import { Component } from '@angular/core';

@Component({
  selector: 'integrity-policy',
  templateUrl: './integrity-policy.component.html',
  styleUrls: ['./integrity-policy.component.scss']
})
export class IntegrityPolicyComponent {

}
