import { Component, OnInit } from '@angular/core';
import { Treatment } from '../../models/Treatment';
import { DataAccessService } from '../../services/data-access.service';

@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
  styleUrls: ['./treatment.component.scss']
})
export class TreatmentComponent implements OnInit {
  treatments: Treatment[];
  constructor(private _dataAccess: DataAccessService) { }

  ngOnInit() {
    this._dataAccess.getTreatsments().subscribe(resp => {
      this.treatments = resp;
    });
  }

}
