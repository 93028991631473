import { Component, OnInit } from "@angular/core";
import { Employee } from "../../models/Employee";
import { DataAccessService } from "../../services/data-access.service";

@Component({
  selector: "app-about",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"]
})
export class AboutUsComponent implements OnInit {
  employees: Employee[] = [];

  constructor(private _dataAccess: DataAccessService) {}

  ngOnInit() {
   this._dataAccess.getEmployees().subscribe(response => this.employees = response);
  }

}
