<div class="prices u-padding-top-small u-padding-bottom-small">
    <div class="row">
        <div class="prices__content u-box-shadow u-padding-top-small u-margin-top-huge">
            <h2 class="heading-secondary u-center-text ">Priser</h2>
            <p class="paragraph">Varje skada eller situation som behöver åtgärdas i munnen är unik. Det är svårt att
                generellt säga vad en behandling kostar utan att först ha gjort en undersökning. Kostnaden styrs av
                tandvårdsförsäkringen. Du kan läsa om den på Försäkringskassans
                <a href="https://www.forsakringskassan.se/privatpers/tandvard" target="_blank">hemsida</a>.
                <br /><br />
                En undersökning kostar cirka 1200 kronor. Ofta tags tandsten bort vid undersökningen, vilket kostar cirka
                500 kronor. <br />Det allmänna tandvårdsbidraget
                skiljer sig beroende på ålder, 300 kronor alternativt 600 kronor, och kan användas för att reducera din
                kostnad. <br />Vid önskemål får du ett skriftligt terapi- och kostnadsförslag samt
                undersökningsprotokoll efter undersökningen.
                <br /><br />Du är alltid välkommen att kontakta oss om du undrar något.</p>
        </div>
    </div>
</div>