import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../../../services/news.service';
import { INews } from '../../../../models/INews';

@Component({
  selector: 'home-section-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class HomeNewsComponent implements OnInit {

  public news: INews;

  constructor(private _newsService: NewsService) { }

  ngOnInit() {
    this.getNews();
  }

  getNews(): void {
    this._newsService
      .getNews()
      .subscribe((resp: INews) => {  
        this.news = resp;
      });
  }
}
